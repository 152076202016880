import {useClient} from 'context/auth-context';
import {
    COMPANY_CACHE,
    BP_CONTRACTS_CACHE,
    PERMISSIONS_CACHE,
    BP_LIST_CACHE,
    SINGLE_BP_CACHE,
    CRP_BP_LIST_CACHE,
    CRP_BP_CACHE,
    CONTRACT_SIGNING_URL_CACHE,
    DASHBOARD_NOTIFICATION_CACHE,
    BUSINESS_ACTIVITIES,
    PAYMENT_TYPES,
    CARD_PURPOSE,
    BP_DOCUMENTS,
    REQUIRED_DOCUMENTS,
    CONTACTS_LIST_CACHE,
    REQUIRED_INFORMATIONS,
} from 'constants/cache';
import {IDocument} from 'types/documents';
import {DashboardLayoutState} from 'types/ui/dashboardLayoutState';
import {
    BusinessPartner,
    BusinessPartnerList,
    Permission,
    PermissionSettings,
    EnableEmailTypes,
    IUpdateBusinessPartnerOnboarding,
    ICompany,
    ICompanyRegistryByName,
    BusinessActivity,
    BusinessPartnerPaymentTypes,
    BusinessPartnerCardPurpose,
    BusinessPartnerRequiredDocument,
    IBusinessPartnerReviewProcess,
    BusinessPartnerRequiredInformation,
} from 'types/api';
import {transformStepTypeToNamed} from 'components/pages/OnboardingPage/onboardingSteps';
import {useNotification} from 'context/notification-context';
import {useBusinessPartner} from 'context/business-partner-context';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {PATCH_HEADERS} from './constants';
import {User} from './users';
import {
    CompanyFormValues,
    IndividualFormValues,
} from 'form/validation/businessPartner';

const BUSINESS_PARTNERS_ENDPOINT = '/web_api/business_partners';

type WithPurpose<T> = T & {purpose: string};

const useAddBusinessPartner = () => {
    const client = useClient();

    return useMutation<
        BusinessPartner,
        unknown,
        WithPurpose<CompanyFormValues> | IndividualFormValues
    >({
        mutationFn: data => {
            return client(BUSINESS_PARTNERS_ENDPOINT, {
                data: {
                    ...data,
                    gwgInfo: {
                        ...(data as CompanyFormValues).gwgInfo,
                        purpose: (data as WithPurpose<CompanyFormValues>)
                            .purpose,
                    },
                },
                method: 'POST',
            });
        },
    });
};

const useUpdateBusinessPartnerOnboarding = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<
        BusinessPartner,
        unknown,
        IUpdateBusinessPartnerOnboarding
    >({
        mutationFn: data => {
            const {businessPartnerId, step, ...rest} = data;
            const updateStep = transformStepTypeToNamed(step);
            return client(`${businessPartnerId}/onboarding`, {
                data: {
                    ...rest,
                    onboardingStepName: updateStep,
                },
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
        onSuccess: response => {
            queryClient.cancelQueries({queryKey: [SINGLE_BP_CACHE]});
            queryClient.setQueryData(
                [SINGLE_BP_CACHE, {bpId: response.id, withGwgInfo: true}],
                () => response,
            );
            queryClient.setQueryData(
                [SINGLE_BP_CACHE, {bpId: response.id}],
                () => response,
            );
        },
    });
};

export interface IOnboardingCard {
    cardTermsAccepted: boolean;
    cardCompanyName: string | null;
    referenceCurrency: string | null;
    payInCardCurrency: boolean | null;
    needsExpenseVat: boolean | null;
}

const useUpdateCardOnboarding = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<
        unknown,
        unknown,
        {
            bpIri: string;
            cardOnboardingData: IOnboardingCard;
        }
    >({
        mutationFn: data => {
            const {bpIri, cardOnboardingData} = data;
            return client(`${bpIri}/card_onboarding`, {
                data: cardOnboardingData,
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [SINGLE_BP_CACHE]});
        },
    });
};

const useUpdatePostalAddress = () => {
    const client = useClient();

    return useMutation<
        BusinessPartner,
        unknown,
        Pick<
            BusinessPartner,
            'address' | 'stateOrProvince' | 'zip' | 'city' | '@id'
        >
    >({
        mutationFn: data => {
            const {'@id': id, ...rest} = data;
            return client(`${id}/update_postal_address`, {
                data: rest,
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
    });
};

const useGetCompaniesList = () => {
    const client = useClient();

    return useMutation<ICompany[], unknown, ICompanyRegistryByName>({
        mutationFn: request =>
            client('/web_api/company_registry/search_by_name', {
                data: request,
                method: 'POST',
            }),
    });
};

export interface IUseGetCompanyData {
    id: string;
    country: string | null;
}

export interface IUseGetCompanyDataResponse {
    city: string;
    houseNumber: string;
    name: string;
    purpose: string;
    street: string;
    zipCode: string;
    dateOfIncorporation: string | null;
    incorporationNumber: string;
    stateOrProvince: string;
}

const useGetCompanyData = (request: IUseGetCompanyData) => {
    const client = useClient();

    return useQuery<IUseGetCompanyDataResponse>({
        queryKey: [COMPANY_CACHE, {id: request.id, country: request.country}],
        queryFn: () =>
            client(`/web_api/company_registry/search_by_id`, {
                data: request,
                method: 'POST',
            }).then(data => data[0]),
        enabled: !!(request.country && request.id),
    });
};

interface IUseGetBusinessPartnerList {
    user: User;
    onlyDirectAccessWithoutOnlyCardUser?: boolean;
    onlyAvailablePeers?: boolean;
    onlyAdmin?: boolean;
}

const useGetBusinessPartnersList = ({
    user,
    onlyDirectAccessWithoutOnlyCardUser,
    onlyAvailablePeers,
    onlyAdmin,
}: IUseGetBusinessPartnerList) => {
    const client = useClient();
    return useQuery<BusinessPartnerList>({
        queryKey: [
            BP_LIST_CACHE,
            {user, onlyDirectAccessWithoutOnlyCardUser, onlyAvailablePeers},
        ],
        queryFn: () =>
            client('/web_api/available_business_partners', {
                params: {
                    onlyDirectAccessWithoutOnlyCardUser,
                    onlyAvailablePeers,
                    onlyAdmin,
                },
            }),
        enabled: !!user,
    });
};

/**
 * DO NOT USE THIS, use the value from the context: useBusinessPartner()
 * @param bpId number
 * @returns BusinessPartner resource
 */
const useGetBusinessPartner = (bpId: number | null, withGwgInfo: boolean) => {
    const client = useClient();

    return useQuery<BusinessPartner>({
        queryKey: [SINGLE_BP_CACHE, {bpId, withGwgInfo}],
        queryFn: () =>
            client(`/web_api/business_partners/${bpId}`, {
                params: {
                    groups: withGwgInfo ? ['GwgInfoView'] : undefined,
                },
            }),
        enabled: !!bpId,
    });
};

export type IOnboardingContract = {
    '@id': string;
    '@type': 'OnboardingContract';
    current: boolean;
    externalIntegrationType: 'skribble' | 'docuSign';
    id: number;
    signingUrl: string;
    status: 'signed';
};

const useGetCurrentContract = (activeBusinessPartner: BusinessPartner) => {
    const client = useClient();

    return useQuery<IOnboardingContract | null>({
        queryKey: [BP_CONTRACTS_CACHE, {bpId: activeBusinessPartner?.['@id']}],
        queryFn: async () => {
            const contracts: IOnboardingContract[] = await client(
                `/web_api/onboarding_contracts?current=1`,
                {},
            );

            return contracts?.find(c => c.current) || null;
        },
        enabled: !!activeBusinessPartner && !activeBusinessPartner.sandbox,
    });
};

const useGetContractSigningUrl = (contractId?: string, enabled?: boolean) => {
    const client = useClient();

    return useQuery<{signingUrl: string}>({
        queryKey: [CONTRACT_SIGNING_URL_CACHE, {id: contractId}],
        queryFn: () =>
            client(`/web_api/contract/${contractId}/signing-url`, {}),
        enabled: !!contractId && enabled,
    });
};

const useGenerateContract = () => {
    const client = useClient();

    return useMutation({
        mutationFn: (businessPartnerId: string) => {
            return client(`${businessPartnerId}/generate-contract`, {
                data: {},
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
    });
};

export interface ISendBusinessPartnerInviteRequest {
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    language: string;
    businessPartner: string;
}

const useSendBusinessPartnerInvite = () => {
    const client = useClient();
    return useMutation<unknown, unknown, ISendBusinessPartnerInviteRequest>({
        mutationFn: data => {
            return client(
                `/web_api/business_partner_invites`,
                {
                    data,
                    method: 'POST',
                },
                false,
                true,
            );
        },
    });
};

const useResendBusinessPartnerInvite = () => {
    const {addNotification} = useNotification();
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<unknown, unknown, {iri: string}>({
        mutationFn: ({iri}) => {
            return client(`${iri}/resend`, {
                ...PATCH_HEADERS,
                data: {},
                method: 'PATCH',
            });
        },
        onSuccess: () => {
            addNotification(
                {
                    area: 'general_settings',
                    message: 'User re-invite sent.',
                    status: 'success',
                },
                'user-re-invited',
            );
            queryClient.invalidateQueries({queryKey: [PERMISSIONS_CACHE]});
        },
    });
};

const useUpdateBusinessPartnerRes = () => {
    const client = useClient();
    return useMutation({
        mutationFn: (hash?: string) => {
            return client(`/web_api/business_partner_invites/${hash}/consume`, {
                data: {},
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
    });
};

const useSuspendAllCards = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: {businessPartnerIri: string; suspendAll: boolean}) =>
            client(`${data.businessPartnerIri}/suspend_all_cards`, {
                data: {
                    allCardsSuspended: data.suspendAll,
                },
                method: 'PATCH',
                ...PATCH_HEADERS,
            }),
        onSuccess: _ => {
            queryClient.invalidateQueries({queryKey: [SINGLE_BP_CACHE]});
        },
    });
};

export interface IConnectedPermissionsResponse {
    '@id': string;
    '@type': string;
    admin: boolean;
    allowedToSign: boolean;
    amountLimit: number;
    businessPartner: {
        '@id': string;
        '@type': string;
        createdAt: string;
        updatedAt: string;
    };
    businessPartnerInvites?: Partial<User>[]; // TODO-CARD
    forwardDaysLimit: number;
    fourEyeSignRequired: boolean;
    fxTrade: boolean;
    limitTrade: boolean;
    swapTrade: boolean;
    createContact: boolean;
    // crp is not string here
    user?: User & {crp: ICrp};
    fullName: string;
    cardAdmin: boolean;
    onlyCardUser: boolean;
    cardHolder: {
        '@id': string;
        '@type': 'CardHolder';
        embossedName: string;
    };
    cardHolderInActivation: boolean;
}

const useGetConnectedPermissions = (activeBpIri: string) => {
    const client = useClient();

    return useQuery<IConnectedPermissionsResponse[]>({
        queryKey: [PERMISSIONS_CACHE, {activeBpIri}],
        queryFn: () =>
            client('/web_api/permissions', {params: {pagination: false}}),
    });
};
export interface IUseUpdateUserSettingsRequest {
    businessPartner: BusinessPartner;
    enableEmailTypes?: EnableEmailTypes;
    dashboardLayout?: DashboardLayoutState;
    settings?: PermissionSettings;
}

const useUpdateUserSettings = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<Permission, unknown, IUseUpdateUserSettingsRequest>({
        mutationFn: data => {
            const {businessPartner, ...rest} = data;
            if (!businessPartner.permission) return Promise.reject();

            return client(`${businessPartner.permission['@id']}/user_update`, {
                data: rest,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [SINGLE_BP_CACHE]});
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [DASHBOARD_NOTIFICATION_CACHE],
            });
        },
    });
};

const useDeletePermission = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({bpPermissionId}: {bpPermissionId: string}) =>
            client(bpPermissionId, {
                data: {},
                method: 'DELETE',
            }),
        onSuccess: () =>
            queryClient.invalidateQueries({queryKey: [PERMISSIONS_CACHE]}),
    });
};

export interface ICrp {
    '@id': string;
    '@type': 'Crp';
    lastName: string;
    firstName: string;
    email: string;
    dateOfBirth: string;
    gender: string;
    country: string;
    street: string;
    zip: string;
    city: string;
    phoneNumber: string;
    nationality: string;
    user: string | null;
    identifiedFirstName: string | null;
    identifiedLastName: string | null;
    identified: boolean;
    idType: string | null;
    idNumber: string | null;
    idExpiryDate: string | null;
    pep: null | boolean;
    pepDescription?: string;
    needIdentification: boolean;
    canChangeName: boolean;
    highestEducation?: string;
}

export interface ICrpBpListItem {
    '@id': string;
    '@type': 'CrpBusinessPartner';
    businessPartner: string;
    id: number;
    crp: ICrp;
    directOwner: boolean;
    ownerPercentage: string;
    role: string;
    isOwner: boolean;
    hasSigningRights: boolean;
    contractSigner: boolean;
    dateOfBirth?: string;
    nationality?: string;
    /**
     * null means no signature needed from this crp
     */
    contractSigned: boolean | null;
    collectiveSigningRight: boolean;
    admin: boolean;
    user?: string;
    confidential: boolean;
    documents: IDocument[];
    gender?: string;
    professionalPosition?: string;
}

const useGetBusinessPartnerCrpList = (
    activeBusinessPartner: BusinessPartner,
) => {
    const client = useClient();

    return useQuery<ICrpBpListItem[]>({
        queryKey: [CRP_BP_LIST_CACHE, {id: activeBusinessPartner?.['@id']}],
        queryFn: () =>
            client('/web_api/crp_business_partners', {
                headers: {
                    'Business-Partner': activeBusinessPartner?.id,
                },
            }),
        enabled: !!activeBusinessPartner,
    });
};

const useGetCrpBusinessPartner = (crpBpId?: string) => {
    const client = useClient();

    return useQuery<ICrpBpListItem>({
        queryKey: [CRP_BP_CACHE, {id: crpBpId}],
        queryFn: () => client(crpBpId!, {}),
        enabled: !!crpBpId,
    });
};

const useDownloadBusinessPartnerDocuments = (bpId: string) => {
    const client = useClient();

    return useQuery<IDocument[]>({
        queryKey: [BP_DOCUMENTS, {bpId}],
        queryFn: () => client('/web_api/documents', {}),
        enabled: !!bpId,
    });
};

type UpdateReferenceCurrency = {
    businessPartner: BusinessPartner;
    referenceCurrency: string;
};
type UpdateCardTransactionSettings = {
    businessPartner: BusinessPartner;
    payInCardCurrency: boolean;
};
type UpdateNeedsExpenseVat = {
    businessPartner: BusinessPartner;
    needsExpenseVat: boolean;
};
type Update4EyeSignRequiredForOwnAccounts = {
    businessPartner: BusinessPartner;
    fourEyeOwnPaymentSignRequired: boolean;
};
export type UpdateBusinessPartner =
    | UpdateReferenceCurrency
    | UpdateNeedsExpenseVat
    | UpdateCardTransactionSettings
    | Update4EyeSignRequiredForOwnAccounts;

const useUpdateBusinessPartner = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<BusinessPartner, unknown, UpdateBusinessPartner>({
        mutationFn: ({businessPartner, ...data}) =>
            client(businessPartner['@id'], {
                data,
                method: 'PATCH',
                ...PATCH_HEADERS,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [SINGLE_BP_CACHE]});
        },
    });
};

export interface IUploadBusinessPartnerDocument {
    files: File[];
    type: string;
    activeBpId: string;
    description?: string;
    category?: string;
    uniqueId?: string | null;
}

const useUploadBusinessPartnerDocument = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<unknown, unknown, IUploadBusinessPartnerDocument>({
        mutationFn: ({
            files,
            type,
            description,
            activeBpId,
            category,
            uniqueId,
        }) => {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('file', file);
            });
            formData.append('type', type);
            if (description) {
                formData.append('description', description);
            }
            if (category) {
                formData.append('category', category);
            }
            if (uniqueId) {
                formData.append('uniqueId', uniqueId);
            }

            return client(`${activeBpId}/documents/upload`, {
                data: formData,
                method: 'POST',
                headers: {'Content-Type': 'multipart/form-data'},
                keepOriginal: true,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [BP_DOCUMENTS]});
        },
    });
};

const useGetBusinessActivities = () => {
    const client = useClient();

    return useQuery<BusinessActivity[]>({
        queryKey: [BUSINESS_ACTIVITIES],
        queryFn: () => client(`/web_api/business_activities`, {}),
    });
};

const useGetBusinessPartnerPaymentTypes = () => {
    const client = useClient();

    return useQuery<BusinessPartnerPaymentTypes[]>({
        queryKey: [PAYMENT_TYPES],
        queryFn: () => client(`/web_api/payment_types`, {}),
    });
};

const useGetBusinessPartnerCardPurposes = () => {
    const client = useClient();

    return useQuery<BusinessPartnerCardPurpose[]>({
        queryKey: [CARD_PURPOSE],
        queryFn: () => client(`/web_api/card_usage_purposes`, {}),
    });
};

const useGetBusinessPartnerRequiredDocuments = (
    businessPartner: BusinessPartner,
) => {
    const client = useClient();

    return useQuery<BusinessPartnerRequiredDocument[]>({
        queryKey: [REQUIRED_DOCUMENTS, {bpId: businessPartner['@id']}],
        queryFn: () =>
            client(`${businessPartner['@id']}/onboarding-needed-documents`, {}),
        enabled: !!businessPartner,
    });
};

const useGetBusinessPartnerRequiredInformations = (
    businessPartner: BusinessPartner,
) => {
    const client = useClient();

    return useQuery<BusinessPartnerRequiredInformation[]>({
        queryKey: [REQUIRED_INFORMATIONS, {bpId: businessPartner['@id']}],
        queryFn: () =>
            client(
                `${businessPartner['@id']}/onboarding-needed-informations`,
                {},
            ),
        enabled: !!businessPartner,
    });
};

const useAddBusinessPartnerRequiredInformations = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<
        BusinessPartnerRequiredInformation[],
        unknown,
        {
            infos: BusinessPartnerRequiredInformation[];
            businessPartner: BusinessPartner;
        }
    >({
        mutationFn: data =>
            client(
                `${data.businessPartner['@id']}/onboarding-needed-informations`,
                {
                    data: data.infos,
                    method: 'POST',
                },
            ),
        onSuccess: () =>
            queryClient.invalidateQueries({queryKey: [REQUIRED_INFORMATIONS]}),
    });
};

const useTriggerReviewProcess = () => {
    const {activeBusinessPartner} = useBusinessPartner();
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<IBusinessPartnerReviewProcess, unknown>({
        mutationFn: () =>
            client('/web_api/review_processes', {
                method: 'POST',
                data: {
                    businessPartner: activeBusinessPartner['@id'],
                },
            }),
        onSuccess: response => {
            queryClient.cancelQueries({queryKey: [SINGLE_BP_CACHE]});
            queryClient.setQueryData(
                [
                    SINGLE_BP_CACHE,
                    {bpId: activeBusinessPartner.id, withGwgInfo: false},
                ],
                (prev: BusinessPartner) => ({
                    ...prev,
                    reviewProcess: response,
                }),
            );
            queryClient.setQueryData(
                [
                    SINGLE_BP_CACHE,
                    {bpId: activeBusinessPartner.id, withGwgInfo: true},
                ],
                (prev: BusinessPartner) => ({
                    ...(prev ?? activeBusinessPartner),
                    reviewProcess: response,
                }),
            );
            queryClient.invalidateQueries({queryKey: [SINGLE_BP_CACHE]});
        },
    });
};

interface IUseAddPeerBusinessPartners {
    activeBusinessPartner: BusinessPartner;
    businessPartnerIds: string[];
}

const useAddPeerBusinessPartners = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<unknown, unknown, IUseAddPeerBusinessPartners>({
        mutationFn: ({activeBusinessPartner, businessPartnerIds}) =>
            client(`${activeBusinessPartner['@id']}/add_peers`, {
                method: 'POST',
                data: {
                    ids: businessPartnerIds,
                },
            }),
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [CONTACTS_LIST_CACHE],
            }),
    });
};

export {
    useGetCurrentContract,
    useAddBusinessPartner,
    useUpdateBusinessPartnerOnboarding,
    useGetCompaniesList,
    useGetCompanyData,
    useGenerateContract,
    useSendBusinessPartnerInvite,
    useUpdateBusinessPartnerRes,
    useGetConnectedPermissions,
    useUpdateUserSettings,
    useDeletePermission,
    useGetBusinessPartnersList,
    useGetBusinessPartner,
    useGetBusinessPartnerCrpList,
    useGetCrpBusinessPartner,
    useUploadBusinessPartnerDocument,
    useUpdatePostalAddress,
    useGetContractSigningUrl,
    useGetBusinessActivities,
    useGetBusinessPartnerPaymentTypes,
    useGetBusinessPartnerCardPurposes,
    useGetBusinessPartnerRequiredDocuments,
    useSuspendAllCards,
    useDownloadBusinessPartnerDocuments,
    useUpdateCardOnboarding,
    useUpdateBusinessPartner,
    useResendBusinessPartnerInvite,
    useTriggerReviewProcess,
    useAddPeerBusinessPartners,
    useGetBusinessPartnerRequiredInformations,
    useAddBusinessPartnerRequiredInformations,
};
