import {LegalType} from 'types/onboarding';
import {
    CardTransactionSource,
    TransactionDetailKeys,
    TransactionTypes,
} from 'types/transactions';

export const LOCAL_STORAGE_AUTH_KEY = '__auth_provider_token__';
export const LOCAL_STORAGE_BP_KEY = '__active_bp__';
export const LOCAL_STORAGE_ACCEPT_IDENTIFICATION_TERMS_KEY =
    '__accepted-identification-terms__';
export const LOCAL_STORAGE_ACCEPT_CONTRACT_SIGN_TERMS =
    '__accepted-contract-sign-terms__';

export const PATH = {
    SETTLEMENT_ACCOUNTS: '/payment-instructions',
    BEXIO: '/bexio',
    DASHBOARD: '/dashboard',
    CURRENCIES: '/currencies',
    CARDS: '/cards',
    CARD_DETAILS: '/cards/:id(\\d+)',
    CARD_GROUPS: '/cards/card-groups',
    CARD_GROUP: '/cards/card-groups/:id(\\d+)',
    NEW_CARD: '/new-card',
    PAYMENTS: '/payments',
    PAYOUT: '/payments/payout',
    MULTI_PAYMENT: '/payments/multipay',
    MULTI_PAYMENT_GROUP: '/payments/multipay-group',
    MULTI_PAYMENT_DETAILS: '/payments/multipay-details',
    PAYMENT_FILE_UPLOAD: '/payments/file-upload',
    BATCH_PAYMENT_SIGNING: '/payments/batch-payment-signing',
    PLANING: '/planing',
    ACCOUNT_STATEMENTS: '/account-statements',
    SETTINGS: '/settings',
    SUPPORT: '/support',
    ACCOUNTS: '/accounts',
    ACCOUNT_DETAILS: '/accounts/:currency',
    CONTACTS: '/beneficiaries',
    CONTACT_CREATE: '/beneficiaries/create',
    CONTACT_DETAILS: '/beneficiaries/:id(\\d+)',
    CONTACT_EDIT: '/beneficiaries/:id(\\d+)/edit',
    CONTACT_BANK_CREATE: '/beneficiaries/:id(\\d+)/bank-account/create',
    CONTACT_PAYMENT_TRACKING:
        '/beneficiaries/:id(\\d+)/activate-payment-tracking',
    CONTACT_W2W: '/beneficiaries/:id(\\d+)/create-peer-payment',
    LOGIN: '/login',
    LOGIN_WIR: '/login/wir',
    LOGIN_A352: '/login/a352',
    REGISTRATION: '/create-an-account',
    REGISTRATION_WIR: '/create-a-wir-account',
    REGISTRATION_A352: '/create-an-a352-account',
    REGISTRATION_BEXIO_INIT: '/bexio-login',
    REGISTRATION_BEXIO: '/create-a-bexio-account',
    REGISTRATION_RATE_ALERT: '/create-an-alert-account',
    CONTACT_INVITE: '/contact-invite/:hash',
    CARD_ONBOARDING: '/card-onboarding',
    BP_INVITE: '/business-partner-invite/:hash',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    ADD_BUSINESS_PARTNER: '/add-business-partner',
    ONBOARDING_OVERVIEW: '/onboarding-overview',
    VERIFY_EMAIL: '/verify-email/:hash',
    JUMIO_ERROR: '/jumio/error',
    JUMIO_SUCCESS: '/jumio/success',
    SKRIBBLE_SUCCESS: '/contract-signed',
    SKRIBBLE_ERROR: '/contract-sign-error',
    CHANGE_PASSWORD: '/change-password',
    ADMIN: '/admin',
    COMPANY_DETAILS_SETTINGS: '/admin/company-details',
    USER_MANAGEMENT: '/admin/user-management',
    CARD_AND_EXPENSE_MANAGEMENT: '/admin/card-and-expense-management',
    PAYMENTS_AND_FX_MANAGEMENT: '/admin/payments-and-fx-management',
    INTEGRATIONS_MANAGEMENT: '/admin/integrations-management',
    INTEGRATIONS_BEXIO: '/admin/integrations-management/bexio',
    UPLOAD_DETAILS: '/upload-details',
    ADDITIONAL_BUSINESS_PARTNER: '/additional-business-partner',
    ONBOARDING_NEW: '/onboarding-new',
    PRICING: '/pricing',
    COLLECTIONS: '/collections',
    PAYMENT_REQUEST: '/payment-request',
    PAYMENT_REQUEST_PUBLIC: '/payment-request-public',
    PAYMENT_REQUEST_RECONCILIATION: '/payment-request-reconciliation',
    IMPORT_PAYMENTS_PAGE: '/import-payments',
    IMPORT_CONTACTS_PAGE: '/import-contacts',
    TOOLS: '/tools',
    VALUATIONS: '/tools/valuations',
    VOLUME_STATS: '/tools/volume-stats',
    RATE_ALERT: '/tools/rate-alert',
    PRODUCT_TOURS: '/tools/product-tours',
    TAGS: '/tools/insights/tags',
    INSIGHTS: '/tools/insights',
    STATEMENTS: '/tools/statements',
    DOCUMENT_CENTER: '/tools/document-center',
    REFER: '/refer',
    AUTOMATION: '/automation',
    NEW_AUTOMATION: '/automation/new',
    PAYMENT_TRACKING: '/payment-tracking',
    EXPENSES_PUBLIC: '/expenses-public',
    THIRD_PARTY_AUTH: '/third-party-authorization',
    MERCHANT_DETAILS: '/cards/merchant/:id(\\d+)',
    MERCHANT: '/cards/merchant',
    UPLOADS_REQUIRED: '/payments/uploads-required',
};

export const TIMEOUT_DEAL_ERROR = 10000;

export interface IFeeScheduleOption {
    value: string;
    label: string;
}

export const FEE_OPTIONS = (
    ccRoute = false,
    country?: string,
): IFeeScheduleOption[] => {
    // To Romania through CC only SHAR is possible
    if (ccRoute && country?.split('/').pop() === 'RO') {
        return [
            {
                value: 'SHAR',
                label: 'Payment fees shared (SHAR)',
            },
        ];
    }
    const options: IFeeScheduleOption[] = [
        {
            value: 'DEBT',
            label: 'You pay all the fees (DEBT/OUR)',
        },
        {
            value: 'SHAR',
            label: 'Payment fees shared (SHAR)',
        },
    ];
    if (!ccRoute) {
        options.push({
            value: 'CRED',
            label: 'Beneficiary pays all costs (BEN/CRED)',
        });
    }

    return options;
};

export const FEE_OPTIONS_TOOLTIP = ({
    ccRoute,
    currency,
}: {
    ccRoute?: boolean;
    currency?: string;
}) => {
    const options = [
        {
            key: 'DEBT',
            value: `You pay all the fees and the recipient gets the full amount (You pay ${currency} 20 and ${currency} 30 after the free allowance)`,
        },
        {
            key: 'SHAR',
            value: `Share fees with the recipients (You pay ${currency} 10 after the free allowance)`,
        },
    ];
    if (!ccRoute)
        options.push({
            key: 'CRED',
            value: `The recipient pays all the fees (You pay nothing)`,
        });
    return {
        text: 'Correspondent banks are permitted to detract a fee of any amount directly from funds being transferred using the SWIFT network. You can choose out of 3 possibilities:',
        options,
    };
};

export const PAGINATION_LIMIT = 10;
export const ONE_HUNDRED = 100;

// Each type of transactions has specific field with link for getting details
// We need this mapping for that Frontend know about required field
export const TRANSACTIONS_DETAILS_MAPPING: Record<
    TransactionTypes,
    TransactionDetailKeys
> = {
    WalletTransaction: 'walletTransactionDetail',
    PayoutTransaction: 'payoutTransactionDetail',
    PayinTransaction: 'payinTransactionDetail',
    FxTransaction: 'fxTransactionDetail',
    FeeTransaction: 'feeTransactionDetail',
    CardTransaction: 'cardTransactionDetail',
} as const;
export type TransactionDetailKeysMapping = typeof TRANSACTIONS_DETAILS_MAPPING;
export const hasDetailKey = <K extends keyof any>(
    obj: any,
    key: K,
): obj is {[P in K]: any} => {
    return key in obj;
};

export const TRANSACTIONS_TEXTS_MAPPING = {
    WalletTransaction: 'PEER-payment',
    PayoutTransaction: 'Pay-out',
    PayoutTransactionDetail: 'Pay-out',
    PayinTransaction: 'Pay-in',
    FxTransaction: 'Currency Exchange',
    FeeTransaction: 'Fee-payment',
    PaymentRequest: 'Payment request',
    recurring: 'Recurring transaction',
    CardTransaction: 'Debit card payment',
    CardTransactionDetail: 'Debit card payment',
};

export const CARD_TRANSACTION_SOURCE_MAPPING: Record<
    CardTransactionSource,
    string
> = {
    Online: 'Online',
    InWalletPOS: 'POS transaction',
    OutOfWalletPOS: 'POS transaction',
    InWalletATM: 'Cash withdrawal',
    OutOfWalletATM: 'Cash withdrawal',
};

export const GENDER_OPTIONS = [
    {
        value: 'male',
        label: 'Mr',
    },
    {
        value: 'female',
        label: 'Mrs',
    },
];

export const ACCOUNTS_TRANSACTION_TYPES = [
    {
        '@id': 'payin_transaction',
        name: 'Pay-in',
    },
    {
        '@id': 'payout_transaction',
        name: 'Pay-out',
    },
    {
        '@id': 'wallet_transaction',
        name: 'PEER payment',
    },
    {
        '@id': 'fee_transaction',
        name: 'Fee-payment',
    },
    {
        '@id': 'fx_transaction',
        name: 'Currency exchange',
    },
    {
        '@id': 'card_transaction',
        name: 'Card transaction',
    },
];

export const OUTGOING_TRANSACTION_TYPES = [
    {
        '@id': 'payout_transaction',
        name: 'Pay-out',
    },
    {
        '@id': 'wallet_transaction',
        name: 'PEER payment',
    },
];

export const INCOMING_TRANSACTION_TYPES = [
    {
        '@id': 'payin_transaction',
        name: 'Pay-in',
    },
    {
        '@id': 'wallet_transaction',
        name: 'PEER payment',
    },
];

export const SOURCE_OF_FUNDS: {
    [key in LegalType]: {'@id': string; label: string}[];
} = {
    [LegalType.INDIVIDUAL]: [
        {
            '@id': 'SALARY',
            label: 'Employment income',
        },
        {
            '@id': 'BUSINESS REVENUE',
            label: 'Income from my business / company',
        },
        {
            '@id': 'INVESTMENT GAIN',
            label: 'Investment gain',
        },
        {
            '@id': 'RENTAL INCOME',
            label: 'Rental income',
        },
        {
            '@id': 'GIFT',
            label: 'Gift',
        },
        {
            '@id': 'LOAN',
            label: 'Loan',
        },
        {
            '@id': 'SALE OF COMPANY',
            label: 'Sale of my company (part of company)',
        },
        {
            '@id': 'INHERITANCE',
            label: 'Inheritance',
        },
        {
            '@id': 'OTHERS',
            label: 'Others',
        },
    ],
    [LegalType.COMPANY]: [
        {
            '@id': 'INVESTMENT GAIN',
            label: 'Investment gain',
        },
        {
            '@id': 'RENTAL INCOME',
            label: 'Rental income',
        },
        {
            '@id': 'BUSINESS REVENUE',
            label: 'Business revenue',
        },
        {
            '@id': 'OTHERS',
            label: 'Others',
        },
    ],
};

export const LEGAL_FORM_NATURAL_PERSON = '/web_api/legal_forms/8';
export const LEGAL_FORM_SINGLE_PERSON_ENTERPRISE = '/web_api/legal_forms/3';

export const ENABLE_EMAIL_TYPES_CONFIRMATION = [
     {
        key: 'confirmation_fx_deal',
        label: 'FX Deal',
        description:
            'You will receive a confirmation email for every executed currency exchange.',
    },
    {
        key: 'confirmation_limit_deal',
        label: 'Limit Deal',
        description:
            'You will receive a confirmation email for every executed limit order.',
    },
    {
        key: 'confirmation_swap',
        label: 'Swap',
        description:
            'You will receive a confirmation email for every executed swap deal.',
    },
    {
        key: 'cancel_fx_deal',
        label: 'Cancel FX Deal',
        description:
            'You will receive a confirmation email for every cancelled currency exchange.',
    },
    {
        key: 'fx_deal_reminder',
        label: 'Forward Deal reminder',
        description:
            "You will receive a reminder email 2 days prior of the forward deal's value date.",
    },
];

export const ENABLE_EMAIL_TYPES_CARD = [
    {
        key: 'card_tx_authorization',
        label: 'Card authorization',
        description:
            'You will receive a notification email for every card transaction authorization.',
    },
    {
        key: 'card_tx_declined',
        label: 'Card transaction declined',
        description:
            'You will receive a notification email for every declined card transaction.',
    },
    {
        key: 'card_tx_refund',
        label: 'Card transaction refund',
        description:
            'You will receive a notification email for every refunded card transaction.',
    },
];

export const ENABLE_EMAIL_TYPES_INCOMING = [
    {
        key: 'incoming_payment_payin',
        label: 'Pay-ins',
        description:
            'You will receive an email when you fund your amnis account.',
    },
    {
        key: 'incoming_payment_collection',
        label: 'Collections',
        description:
            'You will receive an email when you receive a deposit from a 3rd party.',
    },
    {
        key: 'incoming_payment_returned',
        label: 'Returned payment',
        description:
            'You will receive an email if your payment has been returned.',
    },
    {
        key: 'incoming_payment_w2w',
        label: 'Wallet-to-wallet',
        description:
            'You will receive an email when you make a wallet-to-wallet transaction i.e. transfer money between two amnis accounts',
    },
];

export const ENABLE_EMAIL_TYPES_OUTGOING = [
    {
        key: 'outgoing_payment_file',
        label: 'File payments',
        description:
            'You will receive a confirmation email when you capture a file payment.',
    },
    {
        key: 'outgoing_payment_single',
        label: 'Single payments',
        description:
            'You will receive a confirmation email when you capture a regular payment to a beneficiary.',
    },
    {
        key: 'outgoing_payment_w2w',
        label: 'Wallet-to-wallet',
        description:
            'You will receive a confirmation email when you make a wallet-to-wallet transaction i.e. transfer money between two amnis accounts.',
    },
    {
        key: 'outgoing_fee_payment_single',
        label: 'Fee transaction',
        description:
            'You will receive a confirmation email when a fee is debited or credited to your account.',
    },
    {
        key: 'missing_signature',
        label: 'Missing signature',
        description:
            'If you have signing rights, you will receive notification email if a payment requires your signature, sent both before and after the planned execution date.',
    },
];

export const ENABLE_EMAIL_TYPES_RATE_ALERT = [
    {
        key: 'custom_rate_alert',
        label: 'Custom rate alert',
        description:
            'You will receive an email as soon as your target rate is reached',
    },
    {
        key: 'daily_rates',
        label: 'Daily rate alert',
        description: 'You receive a live rate every day at 9am',
    },
];

export const ENABLE_EMAIL_TYPES_FORWARD_TRADING = [
    {
        key: 'forward_trading_margin_call',
        label: 'Margin call',
        description:
            'You will receive an email as soon as your collateral limit is breached.',
        disabled: true,
        checked: true,
    },
    {
        key: 'forward_trading_alert',
        label: 'Alert before margin call',
        description:
            'You will receive an email once 60% of your collateral balance has been utilised.',
    },
    {
        key: 'forward_trading_halted',
        label: 'Forward trading on hold',
        description:
            'You will receive an email once 80% of your collateral balance has been utilised.',
    },
    {
        key: 'forward_trading_enabled',
        label: 'Forward trading resumed',
        description:
            'You will receive an email once your forward trading is reactivated.',
    },
    {
        key: 'forward_trading_status',
        label: 'Open forward positions (weekly)',
        description:
            'You will receive an email informing your current open positions.',
    },
];

export const DIRECT_OWNER_OPTIONS = [
    {
        value: 'yes',
        label: 'Yes.',
    },
    {
        value: 'no',
        label: 'No, (s)he has is holding an indirect stake through another entity.',
    },
];

export const OWNER_PERCENTAGE_OPTIONS = [
    {label: '1-25%', value: '25'},
    {label: '26%-50%', value: '50'},
    {label: '51%-100%', value: '100'},
];

export const SIGNER_OPTIONS = [
    {
        value: 'individual',
        label: 'By individual signature',
    },
    {value: 'collective', label: 'By collective signature'},
];

export const SOURCE_OF_REFFERAL = [
    {
        '@id': 'GoogleAds',
        label: 'Search engine  (Google, Bing, others)',
    },
    {
        '@id': 'PEER',
        label: 'PEER invitation',
    },
    {
        '@id': 'LinkedIn',
        label: 'LinkedIn',
    },
    {
        '@id': 'Facebook',
        label: 'Facebook',
    },
    {
        '@id': 'tradeshow',
        label: 'Event',
    },
    {
        '@id': 'Webinar',
        label: 'Webinar',
    },
    {
        '@id': 'Bexio',
        label: 'bexio',
    },
    {
        '@id': 'Clientreferral',
        label: 'I have a referral code',
    },
    {
        '@id': 'Web registration',
        label: 'Other',
    },
];

export const PROFESSIONAL_POSITION_OPTIONS = [
    {
        '@id': 'boardMember',
        label: 'Board of Directors / Executive Board / Supervisory Board / Management',
    },
    {'@id': 'management', label: 'Management employee(s) / Civil servant(s)'},
    {'@id': 'employee', label: 'Employee / Pensioner'},
    {'@id': 'student', label: 'Student / Trainee'},
];

export const HIGHEST_EDUCATION_OPTIONS = [
    {
        '@id': 'masters',
        label: "Graduation from a university or college with a master's degree",
    },
    {
        '@id': 'bachelor',
        label: "University or college entrance qualification (Abitur) / Bachelor's degree from a university or college",
    },
    {'@id': 'vocational', label: 'Completion of a vocational training'},
    {'@id': 'other', label: 'Other education'},
];

export const RESTRICTED_ACCOUNT_TEXT =
    'Account temporarily restricted. Contact your relationship manager for details.';
